/* Some of the styles are not supported by tailwind yet :(  */

/* Left Half - Ellipse */

* {
  font-family: "Poppins";
  font-style: normal;
}

.bg_style {
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    243.75deg,
    #823598 -48.07%,
    #73108f 11.29%,
    #6e018d 70.65%
  );
}

.left_half {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  transition: width 0.5s ease-out;
}

.ellipse_bg {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 340px;
  height: 340px;
  border-radius: 50%;
  background: #75129000;
  border: 2px solid transparent;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0);
  transition: all 0.3s ease-out;
}

.ellipse_bg:hover {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 345px;
  height: 345px;
  border-color: #751290;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.25);
}

.ellipse_round {
  position: absolute;
  width: 261px;
  height: 261px;
  border-radius: 50%;
  background: linear-gradient(180.09deg, #ffffff -27.85%, #823598 99.93%);
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.ellipse_semicircle {
  height: 170px;
  width: 340px;
  margin-top: 170px;
  border-radius: 0px 0px 170px 170px;
  background: rgba(116, 18, 144, 0.3);
  backdrop-filter: blur(30px);
  -webkit-backdrop-filter: blur(30px);
  /* Note: backdrop-filter has minimal browser support */
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

/* Right Half */
.right_half {
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  top: 0;
  width: 50%;
  height: 100%;
  margin-left: 50%;
  background-color: #ffffff;
  transition: 0.5s ease-out;
}

.byjus_logo {
  position: absolute;
  top: 34px;
  right: 34px;
}

/* Media queries */
@media only screen and (max-width: 900px) {
  .left_half {
    width: 0;
    transition: width 0.5s ease-out;
  }
  .right_half {
    width: 100%;
    margin-left: 0;
    transition: margin-left 0.5s ease-out;
  }
}
